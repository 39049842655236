body {
    --darkprimary-color: #222222;
    --primary-color: #f95e14;
    --secondary-color: #f95e14;
    --accent-color: #4BB6FB;

    --white-color: #ffffff;
    --black-color: #000000;
    --grey-color: #4C4C4C;
    --backgroundgrey-color: #F7F7F7;
    --lightgrey-color: #A4A4A4;
    --lg-color: #B9B9B9;
    --shadow-color: rgba(0,0,0,.16);
    --darkshadow-color: rgba(0,0,0,.4);
    --whiteshadow-color: rgba(255,255,255,.14);
    --lightshadow-color: rgba(112,112,112,.06);
}